import Aegis from '@tencent/aegis-web-sdk-v2';
import newFunction from './new-function';
const GALILEO_URL = 'https://galileotelemetry.tencent.com/collect';
const DEFAULT_PLUGIN = {
    fid: true,
    ie: true,
    spa: true,
    pagePerformance: true,
    report: true,
    assetSpeed: true,
    apiSpeed: true,
    reportRequest: true,
    session: true,
    api: {
        apiDetail: true,
        retCodeHandler(data) {
            try {
                const body = JSON.parse(data);
                const code = body.code ?? body.ret ?? body.retcode ?? body.errcode;
                if (String(code) === '200' || String(code) === '0')
                    return { isErr: false, code: '0' };
                return { isErr: true, code: String(code) };
            }
            catch (e) {
                return { isErr: false, code: e.message };
            }
        },
        // injectTraceHeader: 'traceparent', // FIXME: 跨域
    },
};
export function injectAegis({ rtx, project, projectId, mode }) {
    const deployConfig = window.WUJI_SWITCH?.aegis_config ?? {}; // 部署级配置
    let { open = false } = deployConfig;
    const { id } = deployConfig;
    const projectOpen = project.advanceConfig?.aegis; // 项目级配置
    if (open && typeof projectOpen === 'boolean') {
        open = projectOpen;
    }
    const extField = {
        ext1: projectId,
        ext2: mode,
        ext3: '',
        ext4: '', // 当前部署serviceId,会被运行时覆盖
    };
    const defaultConfig = {
        uid: rtx,
        extField,
        delay: 1000 * 10,
        hostUrl: { url: GALILEO_URL },
        plugin: DEFAULT_PLUGIN,
    };
    if (!window.AegisV2 && open && id) {
        window.AegisV2 = new Aegis({
            id,
            ...defaultConfig,
        });
    }
    const { aegisConfig } = project.advanceConfig;
    if (!window.CustomAegisV2 && aegisConfig?.enable) {
        let config = {};
        if (aegisConfig.useAdvanceConfig && aegisConfig.aegisConfigHook) {
            config = newFunction(['w'], aegisConfig.aegisConfigHook)(window.w);
        }
        else if (aegisConfig.aegisId) {
            config = {
                id: aegisConfig.aegisId,
                ...defaultConfig,
            };
        }
        else {
            return;
        }
        window.CustomAegisV2 = new Aegis(config);
    }
}
;
