import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';

const updatePage = async ({ data, pageId, projectId }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix()}/page/${pageId}?projectid=${projectId}`, {
  method: 'PATCH',
  headers,
  body: JSON.stringify(data),
});

const createPage = async ({ data, projectId }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix()}/page?projectid=${projectId}`, {
  method: 'POST',
  headers,
  body: JSON.stringify(data),
});

const deletePage = async ({ projectId, pageId }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix()}/page/${pageId}?projectid=${projectId}`, {
  method: 'DELETE',
  headers,
});

export default {
  updatePage,
  createPage,
  deletePage,
};
