import { get } from 'lodash';
import { message } from 'ant-design-vue';
import exportExcel, { exportExcels, getFieldsArray } from '@utils/export-excel';
import importExcel from '@utils/import-excel';
import { XyExcelFieldConfigMeta, XyExcelEditMeta } from '../paramsFormComponents';
import logger from '@/utils/logger';
import { CATEGORY } from './const';
import { exportCsv } from '@/utils/export-csv';
// Excel导出
export const exportExcelAtom = {
    id: 'xy:exportExcel',
    name: 'Excel导出',
    desc: '支持xlsx、xlsm、csv(单工作表)格式文件的导出',
    category: CATEGORY.EXPORT,
    paramsSchema: {
        type: 'object',
        ui: {
            type: 'XyExcelEdit',
        },
        fields: [
            {
                id: 'jsCode',
                type: 'string',
                title: '导出的数据',
                description: '1、使用页面变量 eg: data.yourSource.listData \n2、使用上一个流程的执行结果 eg: flowState.res.listData',
                ui: {
                    type: 'exprInput',
                    props: {
                        type: 'javascript',
                        multipleLine: true,
                        placeholder: '1、使用页面变量 eg: data.yourSource.listData \n2、使用上一个流程的执行结果 eg: flowState.res.listData',
                    },
                },
            },
            {
                id: 'mode',
                type: 'array',
                title: '配置模式',
                default: 'form',
            },
            {
                id: 'fieldConfig',
                type: 'array',
                title: '字段配置',
                default: [],
                // ui: {
                //   type: 'XyExcelFieldConfigEdit',
                // },
            },
            {
                id: 'fieldConfigJsCode',
                type: 'string',
                title: '动态字段配置',
                default: '',
            },
            {
                id: 'fileName',
                type: 'string',
                title: '文件名',
                default: 'excel',
            },
            {
                id: 'sheetName',
                type: 'string',
                title: '表名',
                default: 'Sheet1',
            },
            {
                id: 'format',
                title: '支持的格式',
                type: 'string',
                enum: [
                    { label: 'xlsx', value: 'xlsx' },
                    { label: 'xlsm', value: 'xlsm' },
                    { label: 'csv', value: 'csv' },
                ],
                default: 'xlsx',
            },
        ],
    },
    paramsFormComponents: [XyExcelFieldConfigMeta, XyExcelEditMeta],
    async execute(ctx, params) {
        const { type = 'single', sheetJsCode, jsCode, fileName, sheetName, format, fieldConfig, mode, fieldConfigJsCode } = params;
        const rtx = get(ctx, 'renderer')?.wContext?.user?.rtx || '';
        if (type === 'multiple') {
            if (format === 'csv') {
                throw new Error('csv格式不支持多sheet导出');
            }
            const sheets = ctx.tryEval(sheetJsCode);
            await exportExcels(sheets, {
                author: rtx,
                fileName,
                format,
            }, () => {
                logger.info('导出成功');
            });
            return;
        }
        const data = ctx.tryEval(jsCode);
        if (!Array.isArray(data)) {
            message.error('[excel导出] 目标数据不是个数组');
        }
        let fields = [];
        if (mode === 'js') {
            fields = ctx.tryEval(fieldConfigJsCode);
            try {
            }
            catch (error) {
                console.error(error);
                message.error('计算字段配置失败');
            }
        }
        else {
            // 老数据是空的，兼容
            fields = fieldConfig;
        }
        if (format === 'csv') {
            const newData = getFieldsArray(data, fields);
            await exportCsv(newData, { columns: fieldConfig.map(item => item.colName), fileName: `${fileName}.csv` });
        }
        else {
            await exportExcel(data, {
                author: rtx,
                fileName,
                sheetName,
                format,
                fields,
            }, () => {
                logger.info('导出成功');
            });
        }
    },
};
// Excel导入
export const importExcelAtom = {
    id: 'xy:importExcel',
    name: 'Excel导入',
    category: CATEGORY.EXPORT,
    paramsSchema: {
        type: 'object',
        fields: [
            {
                id: 'outputType',
                type: 'string',
                title: '输出的数据类型',
                description: '后续步骤可通过 `flowState.__file__` 获取导入的文件对象',
                default: 'json',
                enum: [
                    { label: 'JSON', value: 'json' },
                ],
            },
            {
                id: 'raw',
                type: 'boolean',
                title: '原始数据',
                default: false,
            },
            {
                id: 'cellDates',
                type: 'boolean',
                title: '以 Date 输出日期类型',
                default: true,
            },
            {
                id: 'codepage',
                type: 'string',
                title: '字符集',
                enum: [
                    {
                        label: '默认',
                        value: '',
                    },
                    {
                        label: 'UTF-8',
                        value: '65001',
                    },
                    {
                        label: 'GBK / GB2312',
                        value: '936',
                    },
                ],
                default: '',
            },
            {
                id: 'parseTimeout',
                type: 'number',
                title: '解析 Excel 的超时时间(毫秒)',
                default: 1000,
            },
        ],
    },
    execute: async (ctx, params) => {
        const { raw = false, cellDates = true, codepage, parseTimeout = 1000 } = params;
        const result = await new Promise((rs) => {
            const elInput = document.createElement('input');
            let isFileCancel = true;
            elInput.setAttribute('type', 'file');
            elInput.setAttribute('accept', '.xlsx, .xls, .csv');
            elInput.setAttribute('style', 'display: none');
            elInput.addEventListener('change', () => {
                isFileCancel = false;
                const [file] = elInput.files ?? [];
                if (file) {
                    if (ctx?.state)
                        ctx.state.__file__ = file;
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const data = e?.target?.result;
                        const option = { raw, cellDates };
                        if (codepage)
                            option.codepage = parseInt(codepage, 10);
                        const excelData = await importExcel(data, option);
                        elInput.remove();
                        rs(excelData);
                    };
                    reader.readAsBinaryString(file);
                }
                else {
                    rs(null);
                }
            });
            // 模拟选择文件的取消操作
            window.addEventListener('focus', () => {
                setTimeout(() => {
                    if (isFileCancel) {
                        rs(null);
                    }
                }, parseTimeout);
            }, { once: true });
            document.body.appendChild(elInput);
            elInput.click();
        });
        return result;
    },
    returnValue: 'Excel导入的数据',
};
export default [
    exportExcelAtom,
    importExcelAtom,
];
