import { getWujiCompInstance } from '@utils/comps-loader';
import pluginLoader from '@/loaders/plugin/loader';
import ucComponentPlugins from '@/ucComponentPlugins';
import logger from '@/utils/logger';
import { getPlatformUICorePlugins } from './uicore-platform-plugins';


const mixins = {
  data() {
    return {
      ucPlugins: {
        platformPlugins: [],
        componentPlugins: [],
        pagePlugins: [],
      },
    };
  },

  computed: {
    finalUcPlugins() {
      const answer = [this.uicorePlugin];
      // 通过参数阻止 lessCode 执行, 防止 lessCode 有跳转方法执行导致死循环
      if (this.$route?.query?.lesscode !== '0') answer.push(this.lessCodePlugin);
      answer.push(...this.ucPlugins.platformPlugins);
      answer.push(...this.ucPlugins.componentPlugins);
      answer.push(...this.ucPlugins.pagePlugins.map(p => p.plugin));

      const wPageletPlugin = {
        install: (uc) => {
          uc.hooks.pageletRendererCreated.tap('wPageletPlugin', (renderer) => {
            if (!renderer.ucIsRoot) return;

            // [废弃] mergeParams 兼容旧配置
            if (this.mergeParams) {
              // FIXME: 拆分 mixin
              const params = this.paramsObject ?? this.params;
              Object.keys(params).forEach((key) => {
                renderer.$set(renderer.state, key, params[key]);
              });
            }
          });
        },
      };
      answer.push(wPageletPlugin);
      return Object.freeze(answer);
    },
    groups() {
      return (
        this.wContext.projectConfig?.componentHubGroupsList?.split(',') || []
      );
    },
  },
  created() {
    // 通过监听事件，将挂载在页面标签上的插件组件加载到ucRenderer中
    window.addEventListener('componentPluginLoaded', this.prepareComponentPlugins);
    // 先收集一轮已经注册好的插件
    this.prepareComponentPlugins();
  },
  beforeDestroy() {
    const wujiComp = getWujiCompInstance();
    wujiComp.removeListener('hmrPluginChange', this.hmrPluginChange);
    window.removeEventListener('componentPluginLoaded', this.prepareComponentPlugins);
  },
  methods: {
    async initPlugins(projectid, pageid) {
      // 这两个字段，编辑时和运行时取的不一样
      const projectId = projectid || this.baseData?.projectId || this.config?.projectId;
      const pageId = pageid || this.baseData?.pageId || this.config?.pageId;
      const platformPlugins = getPlatformUICorePlugins({ projectId, pageId });
      const plugins = await pluginLoader.list(projectId, pageId, false);
      this.ucPlugins.pagePlugins = plugins || [];
      this.ucPlugins.platformPlugins = Object.seal(await platformPlugins);
      const wujiComp = getWujiCompInstance();
      // 开始监听插件的hmr
      wujiComp.addListener('hmrPluginChange', this.hmrPluginChange);
    },

    async hmrPluginChange(pluginId, config) {
      logger.info('[plugin hmr] trigger hmr', pluginId, config);
      // 获取更新后的插件实例
      const pluginConfig = pluginLoader.getPluginConfig(pluginId);
      const pluginInstance = pluginLoader.getPluginInstance(pluginConfig, config);

      // 将插件实例替换
      const plugins = this.ucPlugins.pagePlugins;
      const pluginIndex = plugins.findIndex(item => item.pluginId === pluginId);
      if (pluginIndex !== -1) {
        this.$set(this.ucPlugins.pagePlugins, pluginIndex, { pluginId, plugin: pluginInstance });
      }

      // 由于插件涉及到生命周期，所以必须重新加载UICore
      setTimeout(() => {
        this.resetUICore();
      }, 0);
    },

    prepareComponentPlugins() {
      this.ucPlugins.componentPlugins = ucComponentPlugins.getUcPlugins();;
    },
  },
};

export default mixins;
