import { computed, defineComponent, onMounted, onBeforeUnmount, readonly, ref } from '@vue/composition-api';
import qs from 'query-string';
import { pick } from 'lodash';
import { useStore } from '@/store';
import { useRouter } from '@/router/useRouter';
import { getCompsList } from '@/utils/comps-loader';
import { PATH_PREFIX } from '@/config/constant';
import RuntimeReport from '@/reporter/RuntimeReport';
import styles from './dataTable.module.scss';
// query 白名单,这些query会被透传给无极1.0,其他query会被过滤掉
const queryWhiteList = ['appid', 'schemaid', 'menutype', 'pure', 'xy', 'frame'];
export default defineComponent({
    name: 'DataTable',
    props: {
        projectId: {
            type: String,
            required: true,
        },
        pageId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const { route } = useRouter();
        const wujie = ref();
        const origin = ref(`${window.location.origin}${PATH_PREFIX || ''}`);
        const loading = ref(true);
        const plugins = readonly([
            {
                // 兼容wujie容器导致e.target 变成 wujie-app引起el-select的remoteMethod方法入参为undefined问题
                jsLoader: (code, url) => {
                    if (url.includes('element-ui.js')) {
                        return code.replace('e.handleQueryChange(t.target.value)', 'e.handleQueryChange(e.$refs.input.value);');
                    }
                    return code;
                },
            },
        ]);
        const env = computed(() => store.state.app.env);
        const project = computed(() => store.state.runtime.project);
        const appId = ref('');
        const schemaId = ref('');
        // 获取数据表对应的 appId 和 schemaId
        onMounted(async () => {
            await getCompsList({ compsKey: ['public-w-wujie'], register: true });
            const data = await store.dispatch('runtime/getDataTableByPageId', {
                pageId: props.pageId,
                projectId: props.projectId,
                env: env.value,
            });
            if (data) {
                appId.value = data.appId;
                schemaId.value = data.schemaId;
            }
        });
        const queryString = computed(() => {
            const defaultQueries = {
                appid: appId.value,
                schemaid: schemaId.value,
                menutype: 'hide',
                xy: '1',
                frame: '1',
            };
            const combinedQueries = {
                ...defaultQueries,
                ...pick(route.value.query, queryWhiteList),
            };
            return qs.stringify(combinedQueries);
        });
        // 上报页面
        let reporter = null;
        const pageReport = () => {
            reporter = new RuntimeReport({
                projectInfo: {
                    projectId: props.projectId,
                    projectName: project.value?.name,
                },
                pageId: props.pageId,
                envId: env.value,
                reportDimensions: [],
                agentReport: project.value?.advanceConfig?.agentReport ?? false,
            });
            reporter.pv();
        };
        const afterMount = () => {
            loading.value = false;
            pageReport();
        };
        onBeforeUnmount(() => {
            reporter?.pgOut()?.destroy();
        });
        return {
            loading,
            wujie,
            origin,
            queryString,
            plugins,
            appId,
            schemaId,
            afterMount,
        };
    },
    render() {
        return (<div style="position: relative">
        {this.loading && <div class={styles.spin}>
          <a-spin spinning={this.loading}/>
        </div>}
        {/* 无界加载无极 1.0 页面 */}
        {(this.appId && this.schemaId) && <public-w-wujie ref="wujie" props={{ attrs: { src: `${this.origin}/wujie` } }} name={`datatable-${this.appId}-${this.schemaId}`} width="100%" height="100%" afterMount={this.afterMount} url={`${this.origin}/p/edit?${this.queryString}`} plugins={this.plugins}/>}
      </div>);
    },
});
