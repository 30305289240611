// 组件预加载
import '@utils/preload/compPreload';

import '@/config/vue-config-runtime';
import 'moment/locale/zh-cn';
import '@utils/screen';
import '@tencent/ui-core/styles/deps';

import './config';
// 全局样式
import './styles/uno.css';
import './styles/vxe-table-override.scss';
import './styles/uicore-override.scss';
import './styles/global.scss';
import './styles/ant-design-react.less';

import runtimeModeRouter from '@/router/runtimeModeRouter';
import { getProjectRuntimeLessCode, defineProjectModules } from '@/utils/lessCode';
// 全局组件
import SysSpin from '@components/global/spin.vue';
import SysModal from '@components/modal/Modal.vue';
import PageLayout from '@components/pageLayout/PageLayout.vue';
import { preLoader } from '@loaders/runtimePreloader';
import utilsLoader from '@loaders/utils/loader';
import SysPagelet from '@pages/pagelet/index';
import SysPage from '@pages/pagelet/root';
import XyPageContainer from '@components/page/xy-page-container.vue';
import {
  Renderer,
  UcSlot,
  UcScopedSlot,
} from '@tencent/ui-core';
import { injectAegis } from '@utils/aegis';
import { proxyATagToWujiPage, useWujiScrollbar } from '@utils/browser';
import {
  initCompsLoader,
  registerAsyncComponentsByGivenComps,
  registerModuleLoader,
  getCompsList,
} from '@utils/comps-loader';
import { dispatchCustomEvent } from '@utils/customEvent';
import getXyHeaders from '@utils/getXyHeaders';
import { loadUtilScript, loadXLSX } from '@utils/loadUtilScripts';
import { overwriteAntdMessage } from '@utils/message';
import { WujiTips } from '@tencent/wuji-source';
import { computeCurrentPageId, getAppParams } from '@utils/path';
import { getDefaultMixin } from '@utils/runtime-mixin';
import uploader from '@utils/upload';
import { CommonContext } from '@utils/global/api';
import { getUserId, getUserInfo } from '@utils/userInfo';
import { reLogin } from '@components/re-login-modal';
import * as VueCompositionAPI from '@vue/composition-api';
import Antd, { Modal, notification } from 'ant-design-vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import lodash from 'lodash';
import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
import pageletComponentModuleLoader from '@utils/comps-loader/special-module-loader/pagelet';

import { initGlobalContext } from './context/globalContext';
import store from './store/index';
import ucComponentPlugins from './ucComponentPlugins';
import getXyManagePathPrefix from './utils/getXyManagePathPrefix';
import { setupLogger } from './utils/logger';
import { loadScriptError } from './utils/loadScriptError';
import { loadAmdModule } from '@utils/common';
import { mixinMemoryLeakRuntime } from '@utils/vue/initMemoryMixin';
import { reactScripts } from '@utils/preload/getReactScripts';
import { getUiCore } from '@utils/getUiCore';

window.dayjs = dayjs;
window.UcSlot = UcSlot;
window.UcScopedSlot = UcScopedSlot;
dayjs.extend(relativeTime);
dayjs.locale('zh-cn');

setupLogger();

useWujiScrollbar();

// ----themeExtractKeep.begin---
if (ANTDV_CUSTOM) {
  require(ANTDV_CUSTOM);
} else {
  require('./styles/antdv.default.less');
}
// ----themeExtractKeep.end----

// 初始化组件加载器
initCompsLoader({
  vue: Vue,
  'ant-design-vue': Antd,
  'ant-design-vue/lib/locale-provider/zh_CN': zhCN,
  // 页面有需要加载react组件的场景才会加载以下的依赖
  // eslint-disable-next-line function-paren-newline
  loadAntd: async () => await import(
    /* webpackChunkName: "antdreactxy_asyncchunk_noexecute" */
    'antd'),
  reactAdaptor: async () => {
    try {
      const [React, ReactDOM] = await Promise.all(reactScripts.map(loadUtilScript));
      const antd = await window.wujiComp.moduleManage.allWebpackExternals.loadAntd();
      // eslint-disable-next-line function-paren-newline
      const ReactAdaptor = await import(
        /* webpackChunkName: "reactadaptor_asyncchunk_noexecute" */
        '@tencent/wuji-react-adaptor');
      return { React, ReactDOM, ReactAdaptor, antd };
    } catch (err) {
      console.error(err);
    }
  },
  loadVue3Adaptor: async () => {
    try {
      const vue3Bundle = await loadUtilScript('vue3');
      // eslint-disable-next-line function-paren-newline
      const Vue3Adaptor = await import(
        /* webpackChunkName: "vuenextadaptor_asyncchunk_noexecute" */
        '@tencent/wuji-vue-next-adaptor');
      return { Vue3Adaptor, vue3: vue3Bundle.Vue3 };
    } catch (err) {
      console.error(err);
    }
  },
});

Vue.use(Antd);
Vue.use(VueCompositionAPI.default);

// 内存泄露相关
mixinMemoryLeakRuntime(Vue);

Vue.component('UcRenderer', Renderer);
Vue.component(
  'UcExprInput',
  async () => {
    const UICore = await getUiCore();
    return UICore.ExprInput;
  },
);
Vue.component(
  'UcExprInputCompletionProvider',
  async () => {
    const UICore = await getUiCore();
    return UICore.ExprInputCompletionProvider;
  },
);
Vue.component(
  'UcAnyInput',
  async () => {
    const UICore = await getUiCore();
    return UICore.AnyInput;
  },
);

/**
 * 注册全局组件
 */
Vue.component('PageLayout', PageLayout);
Vue.component('WSysModal', SysModal);
Vue.component('WSysPage', SysPage);
Vue.component('WSysPagelet', SysPagelet);
Vue.component('WSysSpin', SysSpin);
Vue.component('XyPageContainer', XyPageContainer);

// 挂载全局变量，这部分有优化空间，部分依赖应该用compsloader或者external来处理，尽量不用window来达到复用的目的
window.lodash = lodash;
// window.UICore = UICore;
window.vue = Vue;
window.Vue = Vue;
window['ant-design-vue'] = Antd;
window.utilsLoader = utilsLoader;
window['@vue/composition-api'] = VueCompositionAPI;
window.xy_runtime_utils = {
  store,
  getXyHeaders,
  getXyManagePathPrefix,
  loadXLSX,
  loadAmdModule,
  reLogin,
  uploader,
  getUserInfo,
};
window.wujiScriptError = loadScriptError;


// 注册特殊组加载逻辑
registerModuleLoader('pagelet', pageletComponentModuleLoader);

// 异步注册组件库组件
registerAsyncComponentsByGivenComps([
  'public-w-dept-rtx-select',
  'public-w-cherry-markdown',
]);

window.define('vue', [], () => Vue);
window.define('ant-design-vue', [], () => Antd);
window.define('dayjs', [], () => dayjs);
window.define('lodash', [], () => lodash);
window.define('@vue/composition-api', [], () => VueCompositionAPI);

overwriteAntdMessage();

// 废弃方法: 需要时应该使用 import { Modal } from 'ant-design-vue'; + Modal.confirm
Vue.prototype.$aConfirm = Vue.prototype.$confirm;

window.ucComponentPlugins = ucComponentPlugins;

const loadIcon = () => {
  const icons = [
    'public-w-icon-show-new',
    'public-w-icon-show:antd',
  ];

  const iconLoadPromise = getCompsList({
    compsKey: icons,
    register: true,
  });

  icons.forEach((icon) => {
    Vue.component(icon, {
      created() {
        iconLoadPromise.then(() => {
          this.$vnode.context.$forceUpdate();
        });
      },
      render(h) {
        return h('span', {
          style: {
            width: 'var(--xy-custom-sidebar-font-size-icon)',
            height: 'var(--xy-custom-sidebar-font-size-icon)',
            'margin-right': '8px',
          },
        });
      },
    });
  });
};
/**
 * runtime Vue实例入口
 */
export default (async function () {
  loadIcon();
  const { projectId, env, branch } = getAppParams();
  // 获取应用配置
  const project = await store.dispatch('runtime/getProject', {
    projectId,
    env,
  });


  const router = await runtimeModeRouter({ projectId, env, branch });
  initGlobalContext(router);
  proxyATagToWujiPage(router);

  // 页面配置 数据源预加载
  const pageId = computeCurrentPageId(router);
  preLoader.load({ projectId, pageId, env });

  store.dispatch('rbac/fetchPermissions', { projectId, env });

  const mode = 'runtime';
  // 这个类的构造函数依赖上面store里dispatch的两个事件!
  const w = new CommonContext({ mode });
  window.w = w;

  injectAegis({ rtx: getUserId(), project, projectId, mode: 'runtime' });

  await defineProjectModules(project, { w });
  const defaultMixin = getDefaultMixin({ project, env, mode, w });
  const projectMixin = getProjectRuntimeLessCode(project, { w });

  const instanceFactory = () => new Vue({
    router,
    store,
    el: '#ui-root',
    provide() {
      return {
        w,
      };
    },
    /* 注入应用的 LessCode */
    mixins: projectMixin ? [defaultMixin, projectMixin] : [defaultMixin],
    // 抛出实例化前自定义事件
    async beforeCreate() {
      // 注入全局实例变量
      Vue.prototype.$app = this;
      // 统一提示器，可自由配置样式
      Vue.prototype.$tips = new WujiTips(project, this);
      dispatchCustomEvent('wuji:beforeCreate');
    },
    // 抛出实例化后挂载前自定义事件
    async created() {
      dispatchCustomEvent('wuji:created');
    },
    // 抛出挂载后自定义事件
    async mounted() {
      // 首屏数据源就绪后触发拉取缓存数据(在 w-container 组件中触发)
      this.$once('allDataSourcesReadyForCache', () => {
        // 异步加载页面列表数据
        store.dispatch('runtime/initPageList', { projectId, env, branch });
      });
      dispatchCustomEvent('wuji:mounted');
    },
    render: h => h('a-config-provider', { attrs: { locale: zhCN } }, [h('router-view')]),
  });

  /* eslint-disable no-underscore-dangle */
  if (window.__POWERED_BY_WUJIE__) {
    let instance;
    window.__WUJIE_MOUNT = () => {
      instance = instanceFactory();
      window.xy_vue_instance = instance;
      return instance;
    };
    window.__WUJIE_UNMOUNT = () => {
      instance.$destroy();
      window?.$message?.destroy();
      notification.destroy();
      Modal.destroyAll();
    };
    if (window.__WUJIE.mount) {
      window.__WUJIE.mount();
    } else {
      window.__WUJIE_MOUNT();
    }
  } else {
    const instance = instanceFactory();
    window.xy_vue_instance = instance;
    return instance;
  }
}());

if (module.hot) {
  module.hot.accept();
}
