<template>
  <page-layout
    :wrap-styles="{
      padding: '24px',
    }"
    :styles="{
      padding: '0',
    }"
  >
    <a-result
      v-if="!applying"
      status="403"
      title="403"
      sub-title="没有权限访问"
    >
      <template
        v-if="isGuest"
        #extra
      >
        <p>请尝试 <a href="/sso/login">登录</a> 后再访问</p>
      </template>
      <template
        v-else
        #extra
      >
        <div v-if="canApplyPermission">
          <a-button
            type="primary"
            @click="goToApply"
          >
            申请权限
          </a-button>
          <a-button
            @click="back"
          >
            返回
          </a-button>
        </div>
        <div
          v-if="env !== 'prod'"
          style="margin-top:12px"
        >
          注意：权限数据环境隔离，当前环境 <span style="color:red">{{ currentEnvName }}</span>
        </div>
      </template>
    </a-result>
    <apply
      v-else
      @back="applying = false"
    />
  </page-layout>
</template>

<script>
import { message } from 'ant-design-vue';
import { mapState, mapActions } from 'vuex';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';
import { getAppParams } from '@utils/path';
import { BASE_PATH } from '@config/constant';
import Apply from './modules/permission/Apply.vue';
import { permissionApi } from '@/services/';
import { getDocLink } from '@/utils/links';
import { isGuest } from '@/utils/userInfo';
import { providePermState } from '@pages/modules/permission/PermState';

export default {
  components: {
    Apply,
  },
  data() {
    return {
      flow: null,
      applying: false,
      authSetting: null,
      isGuest: isGuest(),
      permState: providePermState(),
    };
  },
  computed: {
    ...mapState({
      envList: state => state.env.envList,
    }),
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    env() {
      return this.urlParams.env || 'dev';
    },
    currentEnvName() {
      if (this.env === 'dev') return '开发环境';
      const env = this.envList.find(r => r.env === this.env);
      return env?.name ?? this.env;
    },
    hasCustomRuntimeAuth() {
      return !!window.GLOBAL_INFO?.deploySettingFlag?.hasCustomRuntimeAuth;
    },
    thirdDsAuth() {
      const advanceConfig = window.xy_runtime_project?.advanceConfig;
      return !!(advanceConfig?.authCollectionId && advanceConfig?.authCgiId);
    },
    canApplyPermission() {
      return !this.hasCustomRuntimeAuth && !this.thirdDsAuth;
    },
  },
  watch: {
    '$route.query.applying': {
      handler(applying) {
        if (applying === 'true') this.goToApply();
      },
      immediate: true,
    },
  },
  mounted() {
    this.checkPermission();
    this.init();
  },
  methods: {
    ...mapActions(['fetchEnvList']),
    async init() {
      await this.fetchEnvList();
      await this.permState.initAuthSetting(this.projectId, this.env);
    },
    back() {
      if (history.length > 3) {
        // back to normal page
        history.go(-2);
      } else {
        location.href = BASE_PATH;
      }
    },
    goToApply() {
      if (this.permState.panguMappingEnabled.value) {
        const url = `${getDocLink().PANGU_AUTH_APPLICATION}?projectId=${this.permState.getPanguId(this.env)}`;
        window.open(url, '_blank');
        return;
      }
      if (this.permState.powerMappingEnabled.value) {
        const powerMapInfo = window.GLOBAL_INFO.deploySettingFlag?.powerMapInfo || {};
        const url = (powerMapInfo[this.env] || powerMapInfo['*'] || {}).applyUrl || '';
        const applyUrl = stringInterop(url, { systemId: this.permState.getPowerSystemId(this.env) });
        window.open(applyUrl, '_blank');
        return;
      }
      this.applying = true;
    },
    async checkPermission() {
      const { query } = this.$route;
      const { permission, pageId, url } = query;
      const page = window.xy_runtime_pages.find(i => i.pageId === pageId);
      if (!url) return;
      try {
        const hasPermission = await permissionApi.checkPermission(
          this.projectId, this.env,
          permission, '', pageId, page?.path ?? '',
        );
        if (hasPermission && pageId) {
          const urlObject = new URL(url);
          if (page) {
            this.$router.push(`${page.path}${urlObject.search}`);
          }
        }
      } catch (err) {
        message.error('校验权限失败');
      }
    },
  },
};
</script>
