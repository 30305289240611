import { ref } from '@vue/composition-api';
import { message, Modal } from 'ant-design-vue';
import HostConfigService from '@/services/hostConfig';
export function useHostConfig(projectId) {
    const hostConfigs = ref([]);
    const loading = ref(false);
    const isShowEditForm = ref(false);
    const editFormMeta = ref(null);
    const getHostConfigs = async ({ tips = true, isRuntime = false } = {}) => {
        try {
            loading.value = true;
            hostConfigs.value = await HostConfigService.getHostConfigList(projectId.value, isRuntime);
        }
        catch (err) {
            if (tips)
                message.error('获取应用的短路径配置失败');
        }
        finally {
            loading.value = false;
        }
    };
    const handleAddClick = () => {
        editFormMeta.value = {
            projectId: projectId.value,
            host: '',
            envId: '',
            pathPrefix: '/page',
        };
        isShowEditForm.value = true;
    };
    const handleEditClick = (config) => {
        editFormMeta.value = config;
        isShowEditForm.value = true;
    };
    const handleDeleteClick = async (config) => {
        Modal.confirm({
            title: '确定删除配置?',
            content: '删除后, 该域名对应的短路径访问链接将失效',
            onOk: async () => {
                try {
                    loading.value = true;
                    await HostConfigService.deleteHostConfig(projectId.value, config.id);
                    await getHostConfigs();
                }
                catch (err) {
                    message.error('删除短路径配置失败');
                }
                finally {
                    loading.value = false;
                }
            },
        });
    };
    const handleFormConfirm = async () => {
        isShowEditForm.value = false;
        await getHostConfigs();
    };
    return {
        loading,
        hostConfigs,
        isShowEditForm,
        editFormMeta,
        getHostConfigs,
        handleAddClick,
        handleEditClick,
        handleDeleteClick,
        handleFormConfirm,
    };
}
