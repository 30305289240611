import { ref } from '@vue/composition-api';
import { getPageRequiredComps } from '@/utils/comps-loader/pageconfig-parser';
import { getCompInfo } from '@/utils/comps-loader/special-module-loader/pagelet';
import { getWujiCompInstance, registerComp, getCompsList } from '@/utils/comps-loader';
import getJumpURL from '@/utils/get-jump-url';
import { getSlotsAndEvents, getFullId, getComponentId } from '../utils';
import { DELETE_GROUP } from '../consts';
import openDebugTipModal from '../components/modal/debug-tip-modal';
import { uuidv4 } from '@tencent/ui-core/lib/utils';
const SOURCE = 'PAGELET_COMPONENT_DEBUGGER';
const FROM_DEBUGGING_PAGE = 'FROM_DEBUGGING_PAGE';
const BROADCAST_CHANNEL_ID = 'pagelet-component-debugger';
const DEBUG_TIP_DISPLAY_LOCAL_STORAGE_KEY = 'PAGELET_COMPONENT_DEBUG_TIP_DISPLAY';
const showDebugTip = () => {
    const debugTipDisplay = localStorage.getItem(DEBUG_TIP_DISPLAY_LOCAL_STORAGE_KEY);
    return debugTipDisplay !== 'false';
};
const setDebugTipDisplay = (display) => {
    localStorage.setItem(DEBUG_TIP_DISPLAY_LOCAL_STORAGE_KEY, display ? 'true' : 'false');
};
// 携带这个key的页面会开启调试
export const DEBUG_QUERY_KEY = 'compDebug';
const isStartDebugging = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(DEBUG_QUERY_KEY) === '1';
};
const MESSAGE_TYPE = {
    COMPONENT_UPDATE: 'COMPONENT_UPDATE',
    END_DEBUGGING: 'END_DEBUGGING',
};
const BROADCAST_TARGET = {
    ALL: 'all',
};
/**
 * 根据调试信息更新组件
 * @param data broadcastChannel的data
 * @param instance 页面实例，运行时页面和组件编辑器会提供这个
 * @param bus 总线实例，编辑时页面会提供这个
 * @param debuggingComponentList 调试组件列表
 * @param debuggingSet 同上，不过是个set
 * @param UID channel实例的唯一ID
 */
const updateComponentByDebugMessage = ({ data }, instance, bus, debuggingComponentList, debuggingSet, UID) => {
    switch (data.type) {
        case MESSAGE_TYPE.COMPONENT_UPDATE: {
            const payload = data.payload;
            if (payload.broadcastTarget !== BROADCAST_TARGET.ALL && payload.broadcastTarget !== UID) {
                return;
            }
            debuggingSet.add(payload.id);
            debuggingComponentList.value = Array.from(debuggingSet);
            const compInfo = getCompInfo(payload.extraInfo, payload.name, payload.category, payload.icon);
            const wujiComp = getWujiCompInstance();
            // 为了保证调试组件的顺序，在实例化之前先触发一次
            getCompsList({ compsKey: payload.extraInfo.compDependencies, register: true })
                .then(() => {
                wujiComp.moduleManage.installedModules[payload.id]?.setCompInfo(compInfo);
                instance.updateCompListFromWujiComp?.(payload.extraInfo.compDependencies);
                if (bus) {
                    bus.hmrCompChange(payload.id, compInfo, true);
                }
                else {
                    instance.hmrCompChange(payload.id, compInfo, true);
                }
                registerComp(payload.id, compInfo.comp);
            });
            break;
        }
        case MESSAGE_TYPE.END_DEBUGGING: {
            const payload = data.payload;
            debuggingComponentList.value = debuggingComponentList.value.filter(id => id !== payload.id);
            debuggingSet.delete(payload.id);
            break;
        }
        default:
            break;
    }
};
/**
 * 组件编辑器使用
 *
 * provider
 *  - 发送当前调试组件的信息
 *  - 监听通道中是否有获取组件的信息，如果有，发送一次当前调试信息
 *  - 发送心跳包
 */
export const useDebugProvider = () => {
    const debugging = ref(false);
    const broadcastChannel = ref(null);
    const displayDebugTipModal = async (baseData, componentHubInfo) => {
        const id = componentHubInfo ? getFullId(componentHubInfo.id, componentHubInfo.group) : `local-${baseData.materialId}`;
        if (showDebugTip()) {
            const res = await openDebugTipModal({ componentId: id });
            if (res.success) {
                setDebugTipDisplay(false);
            }
        }
        ;
    };
    const createNewWindow = async ({ pageId, projectId, env, isEditor }) => {
        const query = {
            projectid: projectId,
            pageid: pageId,
            [DEBUG_QUERY_KEY]: '1',
        };
        if (isEditor && env === 'dev') {
            return window.open(getJumpURL('/project/pageconfig', query), '_blank');
        }
        return window.open(getJumpURL(`/app/${env}/${projectId}/${pageId}`, { [DEBUG_QUERY_KEY]: '1' }), '_blank');
    };
    const getBroadcastData = (baseData, propsSchemas, componentInfo, broadcastTarget = BROADCAST_TARGET.ALL) => {
        const { slots, events } = getSlotsAndEvents(baseData);
        const { keys: compDependencies } = getPageRequiredComps(baseData.pageConfig);
        const extraInfo = {
            slots,
            events,
            type: 'pagelet',
            projectId: baseData.projectId,
            materialId: baseData.materialId,
            pagelets: baseData.pageConfig,
            pageletPropSchemas: propsSchemas.filter((schema) => schema.group !== DELETE_GROUP),
            compDependencies: compDependencies,
            fitData: componentInfo?.fitData || '',
        };
        const id = getComponentId(baseData.materialId, componentInfo);
        return {
            id,
            category: componentInfo?.category || '调试组件',
            name: componentInfo?.name || baseData.name,
            icon: componentInfo?.icon || '',
            extraInfo,
            broadcastTarget,
        };
    };
    const broadcast = (baseData, propsSchemas, componentInfo, broadcastTarget = BROADCAST_TARGET.ALL) => {
        if (!debugging.value)
            return;
        broadcastChannel.value.postMessage({
            source: SOURCE,
            type: MESSAGE_TYPE.COMPONENT_UPDATE,
            payload: getBroadcastData(baseData, propsSchemas, componentInfo, broadcastTarget),
        });
    };
    const debuggingSet = new Set();
    const debuggingComponentList = ref([]);
    const UID = uuidv4();
    /**
     * 更新其他组件编辑器中的组件
     * @param instance
     * @param data
     */
    const handleDebugMessage = (instance, { data }) => {
        const { materialId } = instance.baseData;
        const { group } = instance.baseData.config;
        const id = group ? `local-${group}-${materialId}` : `local-${materialId}`;
        const payload = data.payload;
        /**
         * 对于broadcast channel而言，同一个channel实例不会接收到自己发送的消息
         * 所以我们不用担心当前组件会接收到自己发送的消息
         *
         * 但是我们需要担心的B组件引入了A组件，而A组件在一次修改中引入了B组件
         * 如果我们在B组件的编辑器中更新了A，就会导致循环渲染。所以我们需要阻止这次更新。
         *
         * 我们无法阻止的情况是用户在没有开启调试的情况下进行组件的发布，这样只能在页面上出现报错了
         * 不过由于编辑器不会加载依赖自身的组件，所以用户可以回到编辑器中进行修复
         */
        if (payload.extraInfo.compDependencies.includes(id)) {
            instance.checkCircularReference(payload.id);
            return;
        }
        // 更新组件
        updateComponentByDebugMessage({ data }, instance, null, debuggingComponentList, debuggingSet, UID);
    };
    const startDebugging = (instance) => {
        debugging.value = true;
        broadcastChannel.value = new BroadcastChannel(BROADCAST_CHANNEL_ID);
        broadcastChannel.value.onmessage = (e) => {
            // 接受到调试页面的信息时，发送当前调试组件的信息
            if (e.data.source === FROM_DEBUGGING_PAGE) {
                instance.broadcast(instance.baseData, instance.propsSchemas, instance.componentHubInfo, e.data.payload.broadcastTarget);
            }
            // 接收别的在线组件的调试信息
            if (e.data.source === SOURCE) {
                handleDebugMessage(instance, e);
            }
        };
        instance.broadcast(instance.baseData, instance.propsSchemas, instance.componentHubInfo);
    };
    const endDebugging = (instance) => {
        debugging.value = false;
        const id = getComponentId(instance.baseData.materialId, instance.componentHubInfo);
        broadcastChannel.value.postMessage({
            source: SOURCE,
            type: MESSAGE_TYPE.END_DEBUGGING,
            payload: {
                id,
            },
        });
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
    };
    return {
        createNewWindow,
        broadcast,
        displayDebugTipModal,
        startDebugging,
        endDebugging,
        debugging,
    };
};
/**
 * 页面或页面编辑器使用
 *
 * - 监听 provider 发送的调试信息
 * - 发送 请求每一个监听信道的provider 发送当前调试信息
 */
const useDebugger = () => {
    const isDebugging = ref(false);
    const debuggingSet = new Set();
    const debuggingComponentList = ref([]);
    const broadcastChannel = ref(null);
    const UID = uuidv4();
    const handleDebugMessage = (instance, bus, { data }) => {
        updateComponentByDebugMessage({ data }, instance, bus, debuggingComponentList, debuggingSet, UID);
    };
    const addDebugMessageListener = (instance, bus) => {
        if (!isStartDebugging())
            return;
        broadcastChannel.value = new BroadcastChannel(BROADCAST_CHANNEL_ID);
        broadcastChannel.value.onmessage = (event) => {
            if (event.data.source === SOURCE) {
                handleDebugMessage(instance, bus, event);
            }
        };
        broadcastChannel.value.postMessage({
            source: FROM_DEBUGGING_PAGE,
            payload: {
                broadcastTarget: UID,
            },
        });
    };
    const removeDebugMessageListener = () => {
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
    };
    return {
        isDebugging,
        debuggingComponentList,
        addDebugMessageListener,
        removeDebugMessageListener,
    };
};
export default useDebugger;
