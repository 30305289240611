/* eslint-disable no-new-func */
import { HIDDEN_GROUP } from '@/pages/project/pageConfig/pagelet-component-editor/composable/use-props-schema';
import { getPropId, getBindingTitle } from './utils';
const getDataSchemaFromField = (field, group) => {
    const schema = {
        id: getPropId(field),
        type: field.type,
        description: field.desc,
        title: field.title,
        enum: field.enum,
        group,
    };
    if (field.tag === 'data') {
        schema.type = 'string';
        schema.ui = {
            type: 'dataSourceSelector',
        };
    }
    return schema;
};
const getBindingFromField = (field) => {
    if (field.tag === 'data')
        return null;
    return {
        id: getPropId(field),
        required: false,
        main: field.main || false,
        prop: getPropId(field),
        title: getBindingTitle(field),
        event: `update:${field.from}-${field.id}`,
    };
};
// 将低代码组件定义的propsSchema转换成uicore需要的配置
// 主要是binding和propsSchema这两个
const getUICoreConfig = (propsSchemas, fitDataStr) => {
    const fields = [];
    const bindings = [];
    const fitDataContext = {};
    propsSchemas.filter(group => group.id !== HIDDEN_GROUP).forEach((group) => {
        group.fields.forEach((field) => {
            fields.push(getDataSchemaFromField(field, group.group));
            const binding = getBindingFromField(field);
            if (binding) {
                bindings.push(binding);
                fitDataContext[field.id] = binding.id;
            }
        });
    });
    return {
        propsSchema: {
            type: 'object',
            fields: [
                {
                    id: 'pagelets',
                    type: 'object',
                    title: '页面',
                    hidden: true,
                },
                {
                    id: 'slots',
                    type: 'array',
                    title: '插槽',
                    hidden: true,
                },
                {
                    id: 'events',
                    type: 'array',
                    title: '事件',
                    hidden: true,
                },
                {
                    id: 'pageletPropSchemas',
                    type: 'array',
                    title: '参数',
                    hidden: true,
                },
                ...fields,
            ],
        },
        bindings,
        fitData: getFitData(fitDataContext, fitDataStr),
    };
};
const getFitData = (context, fitDataStr) => {
    if (!fitDataStr)
        return undefined;
    // 由于我们会给每一个Props都进行改名，如果用户在fitData中直接使用原始的Props来声明binding
    // 会导致无法获取，所以需要做两件事
    // 1. 用户在fitData中声明的binding时，不要直接使用props，而是[props], 比如
    //    binding: { [foobar]: dataPath } //
    // 2. 我们在生成fitData函数的时候，通过with语法，提供一个context,
    //    这样上下文中的[foobar]才会被替换成真正的props
    return new Function('context', `return (config) => { with(context) { return (${fitDataStr})(config) } }`)(context);
};
export default getUICoreConfig;
