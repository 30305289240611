import { compile, match } from 'path-to-regexp';
import {
  APP_PATH_REGEX,
  BASE_APP_PATH_REGEX,
  BASE_BRANCH_APP_PATH_REGEX,
  BASE_GROUP_APP_PATH,
  BASE_PATH,
  MICRO_APP_BASE_ROUTE,
  MICRO_APP_ORIGIN,
} from '@config/constant';
import { isPlainObject, noop } from 'lodash';
import { getRouterPublicParams } from '@utils/routerPublicParams';
import { message } from 'ant-design-vue';
import qs from 'query-string';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';

// path-to-regexp >= 6.0.0 的版本需要替换通配符 `*` 为 `(.*)`
const wildcardRegex = /\*/g;

/**
 * 获取格式化的路径编译器
 *
 * @param {string} path
 * @returns
 */
export const getPathCompiler = path => compile(path?.replace(wildcardRegex, '(.*)') ?? '');

/**
 * 获取格式化的路径匹配器
 *
 * @param {string} path
 * @returns
 */
export const getPathMatcher = (path, opt = {}) => match(path?.replace(wildcardRegex, '(.*)') ?? '', opt);


// 是否为默认的运行时路径
export const isDefaultBaseAppPath = () => {
  const { pathname } = window.location;
  const matcher = getPathMatcher(BASE_APP_PATH_REGEX, { end: false });
  return !!matcher(pathname);
};

// 是否为分支路径
export const isBaseBranchAppPath = () => {
  const { pathname } = window.location;
  const matcher = getPathMatcher(BASE_BRANCH_APP_PATH_REGEX, { end: false });
  return !!matcher(pathname);
};

export const addPathPrefix = (path) => {
  const { pathPrefix } = window.GLOBAL_INFO;
  return `${pathPrefix}${path}`;
};

export const getParams = (path, pathname, key) => {
  // end为false表示只要pathname开头匹配即可
  const matcher = getPathMatcher(path, { end: false });
  const results = matcher(pathname);
  if (results && results.params && key) {
    return results.params[key];
  }
  return results?.params;
};

export const getAppParams = (key) => {
  const fields = ['env', 'projectId', 'branch'];
  const res = {};
  fields.forEach(k => res[k] = window.RUNTIME_CONFIGS?.[k]);

  return key ? res[key] : res;
};

export const getBaseAppPath = ({ env, projectId, branch = '' }, useOriginal) => {
  if (branch && env === 'dev') {
    const getBranchAppPath = getPathCompiler(BASE_BRANCH_APP_PATH_REGEX);
    return getBranchAppPath({ projectId, branch });
  }
  const baseAppPath = getPathCompiler(useOriginal ? BASE_APP_PATH_REGEX : APP_PATH_REGEX);
  return baseAppPath({ env, projectId });
};

export const getGroupId = () => {
  const { pathname } = window.location;
  const matcher = getPathMatcher(BASE_GROUP_APP_PATH, { end: false });
  const result = matcher(pathname);
  return result?.params?.groupId;
};

// 判断 path 是否为正则表达式的路径
// from path-to-regexp: https://github.com/pillarjs/path-to-regexp/blob/v1.7.0/index.js#L17
export const isRegexPath = (path) => {
  const pathRegexp = new RegExp([
    '(\\\\.)',
    '([\\/.])?(?:(?:\\:(\\w+)(?:\\(((?:\\\\.|[^\\\\()])+)\\))?|\\(((?:\\\\.|[^\\\\()])+)\\))([+*?])?|(\\*))',
  ].join('|'), 'g');
  return pathRegexp.test(path);
};

// 匹配路径中的动态路径参数字段
export const matchPathParams = (path) => {
  const params = [];

  // 匹配 :xxx 参数
  const paramRegex = /:(\w+)/g;
  let paramsMatch;
  while ((paramsMatch = paramRegex.exec(path))) {
    params.push(paramsMatch[1]);
  }

  // 匹配通配符
  const wildcardMatches = path.match(wildcardRegex);
  const wildcardLength = wildcardMatches?.length ?? 0;
  if (wildcardLength > 0) {
    params.push(...Array.from({ length: wildcardLength }, (_, index) => `${index}`));
  }

  return params;
};

export function stringify(queryObj, url = '') {
  let hash = url.match(/#.*/g);
  let result;

  if (hash) {
    // eslint-disable-next-line prefer-destructuring
    hash = hash[0];
    // eslint-disable-next-line no-param-reassign
    url = url.replace(hash, '');
  } else {
    hash = '';
  }
  result = url;

  if (typeof queryObj === 'object') {
    const keys = Object.keys(queryObj);
    const qArray = [];

    for (let i = 0; i < keys.length; i += 1) {
      const t = typeof queryObj[keys[i]];
      if (t !== 'undefined' && t !== 'object') {
        qArray.push(`${keys[i]}=${encodeURIComponent(queryObj[keys[i]])}`);
      }
    }

    const qstr = qArray.join('&');
    // eslint-disable-next-line no-unused-expressions
    qstr && (result = url + (url.indexOf('?') === -1 ? '?' : '&') + qstr);
  }
  return result + hash;
}

export function normalizeRouteConfig({ path, params, query, $route, stringInteropContext }) {
  const isWujiPage = path.startsWith('/');
  let assignPath = path;
  let assignParams = params;
  let assignQuery = query;

  // 插值表达式
  if (stringInteropContext && isPlainObject(stringInteropContext)) {
    (
      {
        path: assignPath,
        params: assignParams,
        query: assignQuery,
      } = stringInteropRouteConfig({ path, params, query, stringInteropContext })
    );
  }

  let queryMap = { ...qs.parse(assignQuery), ...(getRouterPublicParams($route.query)) };
  const paramsMap = qs.parse(assignParams);

  // 判断是否为正则表达式路径
  if (isRegexPath(assignPath)) {
    if (isWujiPage) {
      const compiler = getPathCompiler(assignPath);
      assignPath = compiler(paramsMap, { pretty: true });
    } else {
      const url = new URL(assignPath);
      // 判断是否为 hash 路径
      if (url?.hash) {
        let originQueryString = '';
        // 截取 hash 路径中的 query, 然后拼接配置的 query 参数
        if (url.hash?.includes('?')) {
          originQueryString = url.hash.substring(url.hash.indexOf('?'), url.hash.length);
        }
        assignPath = url.href.replace(originQueryString, '');
        queryMap = { ...queryMap, ...qs.parse(originQueryString) };
      } else {
        const compiler = getPathCompiler(url.pathname);
        assignPath = `${url.origin}${compiler(paramsMap, { pretty: true })}`;
        queryMap = { ...queryMap, ...qs.parse(url.search) };
      }
    }
  }

  return {
    path: assignPath,
    params: paramsMap,
    query: queryMap,
  };
}

// 根据 route 参数跳转页面
export function operatePageUrl(
  { path, params, query },
  { open = false, slash = true, method = 'push', $router, $route, stringInteropContext },
) {
  try {
    const {
      path: normalizePath,
      query: normalizeQuery,
    } = normalizeRouteConfig({ path, params, query, $route, stringInteropContext });

    const queryString = qs.stringify(normalizeQuery, { skipNull: true });
    let fullUrl = normalizePath;
    if (queryString) {
      fullUrl += `?${queryString}`;
    }

    if (open) {
      // 新窗口打开
      if (slash) {
        const { href } = $router.resolve({ path: fullUrl });
        window.open(href);
      } else {
        window.open(fullUrl);
      }
    } else {
      // 当前页面打开
      if (slash) {
        $router?.[method]({
          path: fullUrl,
        }).catch(noop);
      } else {
        window.location.href = fullUrl;
      }
    }
  } catch (err) {
    message.error(`页面/链接跳转失败: ${err.message}`);
  }
}

export function stringInteropRouteConfig({ path, params, query, stringInteropContext }) {
  return {
    path: stringInterop(path, stringInteropContext) || '',
    query: stringInterop(query, stringInteropContext) || '',
    params: stringInterop(params, stringInteropContext) || '',
  };
}

// 根据菜单数据跳转页面
export function toPageByMenu(linkConfig, { method = 'push', $router, $route, stringInteropContext }) {
  const { target } = linkConfig.args;
  const { url: path, params: query = '', pathParams: params = '' } = linkConfig.args;
  const isWujiPage = path.startsWith('/');
  const isNewWindow = target !== 'self';

  operatePageUrl(
    { path, params, query },
    { open: isNewWindow, slash: isWujiPage, method, $router, $route, stringInteropContext },
  );
}

// 根据页面配置数据跳转页面
export function toPage(
  { pageId, pagePath, query, params, newWindow },
  { method = 'push', pages = [], $router, $route },
) {
  let toProjectId = ''; // 页面所属的应用ID
  let toPagePath = '';
  if (Array.isArray(pages)) {
    let page;
    if (pageId) {
      page = pages.find(page => page.id === pageId || page.pageId === pageId);
    } else if (pagePath) {
      page = pages.find(page => page.path === pagePath);
      // 在找不到页面的情况下, 支持直接跳转路径
      if (!page) toPagePath = pagePath;
    }
    if (page) {
      ({ projectId: toProjectId, path: toPagePath } = page);
    }
  }

  // 兜底使用当前路由路径
  if (!toPagePath && !pageId && !pagePath) {
    toPagePath = $route?.path;
  }

  if (!toPagePath) {
    return message.error('无效页面路径');
  }

  // 无极的动态路径页面
  if (toPagePath.startsWith('/') && isRegexPath(toPagePath)) {
    if (!params || Object.keys(params ?? {}).length === 0) {
      return message.error('路径解析失败: 动态路径参数不能为空');
    }
    try {
      const compiler = getPathCompiler(toPagePath);
      toPagePath = compiler(params ?? {}, { pretty: true });
    } catch (err) {
      console.error(err);
      return message.error('动态路径解析失败');
    }
  }

  // 合并 query
  const mergeQuery = {
    ...getRouterPublicParams($route.query),
    ...query,
  };

  // query 字符串化
  let queryString = '';
  if (Object.keys(mergeQuery).length > 0) {
    queryString = `?${qs.stringify(mergeQuery)}`;
  }

  // 检测是否 http协议页面或者https协议页面
  const isHttps = /^(http:|https:|\/\/)/.test(toPagePath);

  if (isHttps) {
    window.open(toPagePath, newWindow ? '_blank' : '_self');
  } else if (newWindow) {
    // 新窗口
    const path = getBaseAppPath({
      env: window.RUNTIME_CONFIGS?.env,
      projectId: toProjectId,
      branch: window.RUNTIME_CONFIGS?.branch,
    });
    window.open(`${location.origin}${path}${toPagePath}${queryString}`);
  } else {
    // 单页跳转
    const result = $router?.[method]({
      path: toPagePath,
      query: mergeQuery,
    });
    if (method === 'resolve') return `${location.origin}${result.href}`;
  }
}

/**
 * 跳转到页面编辑器
 *
 * @export
 * @param {Object} [query={}]
 * @param {boolean} [openNewWindow=false]
 */
export function goToPageConfig(query = {}, openNewWindow = false) {
  const queryString = qs.stringify(query, { skipEmptyString: true, skipNull: true });
  let url = `${BASE_PATH}project/pageconfig`;
  if (queryString) url += `?${queryString}`;
  if (MICRO_APP_ORIGIN) {
    url = `${MICRO_APP_ORIGIN}${url.replace(MICRO_APP_BASE_ROUTE, '')}`;
    window.open(url, '_blank', 'noopener=yes');
    return;
  }

  const { host } = location;
  const absUrl = `${location.protocol}//${host}${url}`;
  if (openNewWindow) {
    window.open(absUrl, '_blank', 'noopener=yes');
  } else {
    window.location.href = absUrl;
  }
}

/**
 * 打开项目发布记录页
 *
 * @export
 * @param {{}} [query={}]
 * @param {string} [env='prod']
 */
export function openVersionList(query = {}, env = 'prod') {
  const queryString = qs.stringify({ ...query, env });
  let url = `${BASE_PATH}project/config/version`;
  if (queryString) url += `?${queryString}`;
  if (MICRO_APP_ORIGIN) {
    url = `${MICRO_APP_ORIGIN}${url.replace(MICRO_APP_BASE_ROUTE, '')}`;
  }
  window.open(url, '_blank', 'noopener=yes');
}

export function computeCurrentPath(router) {
  const path = window.location.pathname.replace(router.options.base, '');
  return path || '';
}

/**
 * 计算当前路径的页面id
 *
 * @export
 * @param {VueRouter} router
 * @return {string} pageId
 */
export function computeCurrentPageId(router) {
  let pageId = '';
  try {
    const path = computeCurrentPath(router);
    const matchedRoute = router.matcher.match(path);
    pageId = matchedRoute?.meta?.pageId ?? '';
  } catch {
    pageId = '';
  } finally {
    return pageId;
  }
}

export function getProjectRuntimeUrl({ projectId, env, pagePath, branch }) {
  const baseAppPath = getBaseAppPath({ env, projectId, branch });
  let url = '';
  if (MICRO_APP_ORIGIN) {
    url = `${MICRO_APP_ORIGIN}${baseAppPath.replace(MICRO_APP_BASE_ROUTE, '')}`;
  } else {
    url = `${location.origin}${baseAppPath}`;
  }
  if (pagePath) {
    url += `${pagePath}`;
  }
  return url;
}

/**
 * 打开应用运行页面
 *
 * @export
 * @param {Object} [query={}]
 */
export function openProjectRuntimeUrl({ projectId, env, pagePath, branch }) {
  const url = getProjectRuntimeUrl({ projectId, env, pagePath, branch });
  window.open(url, '_blank');
}

export function getRandomName(fileName) {
  const uuid = Math.random().toString(36)
    .slice(2);
  const nameList = fileName.match(/(.+)(\..+)$/);
  if (!nameList) {
    return uuid;
  }
  const uploadName = `${uuid}${nameList[2]}`;
  return uploadName;
}

export function verifyFileInfo(fileName) {
  const ChineseCharacterRegex = /[\u4e00-\u9fa5]/;
  if (ChineseCharacterRegex.test(fileName)) {
    throw new Error('文件名不能包含中文字符');
  }
}

export default {
  getParams,
  getAppParams,
  getBaseAppPath,
  stringify,
  isRegexPath,
  openProjectRuntimeUrl,
  getRandomName,
  verifyFileInfo,
};
