import { getRuntimeEnvPath, getDesignEnvPath } from '@config/constant';
import { APPLY_WORKFLOW_ID } from '@pages/modules/permission/constants';
import wujiFetch, { memoizedFetchV2 } from '@utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';
import workflowApi from './workflow';

const getEnvPath = env => (window.RUNTIME_CONFIGS ? getRuntimeEnvPath(env) : getDesignEnvPath(env));
const getManageApiPrefix = env => `${getEnvPath(env)}/manage`;
const getPermissionApiPrefix = env => `${getEnvPath(env)}/rbac`;

const fetchRoles = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const result = memoizedFetchV2(`${prefix}/roles?projectid=${projectId}`);
  return result;
};

const fetchRolesByPermission = async (projectId, env, { permission, pageId }) => {
  const prefix = getPermissionApiPrefix(env);
  const result = memoizedFetchV2(`${prefix}/permission-role?projectid=${projectId}&pageid=${pageId}&permission=${permission}`);
  return result;
};

const fetchPermissions = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const result = memoizedFetchV2(`${prefix}/permissions?projectid=${projectId}`);
  return result;
};

const fetchBuiltinPermissionList = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const result = memoizedFetchV2(`${prefix}/builtin-permission?projectid=${projectId}`);
  return result;
};

const fetchPagePermissionList = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const result = memoizedFetchV2(`${prefix}/page-permission?projectid=${projectId}`);
  return result;
};

const fetchFlowPermissionList = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const result = memoizedFetchV2(`${prefix}/flow-permission?projectid=${projectId}`);
  return result;
};

const createPermission = async (projectId, env, data) => {
  const prefix = getPermissionApiPrefix(env);
  const result = wujiFetch(
    `${prefix}/permission?projectid=${projectId}`,
    { method: 'POST', body: JSON.stringify(data) },
  );
  return result;
};

const updatePermission = async (projectId, env, permission) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/permission/${encodeURIComponent(permission.name)}?${query.toString()}`, {
    method: 'PATCH',
    body: JSON.stringify(permission),
  });
  return result;
};

const checkPermission = async (projectId, env, permission, scope = '', pageId = '', path = '') => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId, permission, scope, pageId, path });
  const result = memoizedFetchV2(`${prefix}/check?${query.toString()}`);
  return result;
};

const debugPermission = async (projectId, env, { rtx, permission, bizId = '', pageId = '', path = '' }) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId, permission, bizId, pageId, path, rtx });
  const result = wujiFetch(`${prefix}/debug?${query.toString()}`);
  return result;
};

const fetchRolePermission = async (projectId, env, role) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  // 变更角色权限后，往往需要立即获取刷新，所以不使用缓存
  const result = wujiFetch(`${prefix}/role-permission/${encodeURIComponent(role)}?${query.toString()}`);
  return result;
};

const fetchRoleView = async (projectId, env, role) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = memoizedFetchV2(`${prefix}/role-view/${encodeURIComponent(role)}?${query.toString()}`);
  return result;
};

const fetchUserView = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = memoizedFetchV2(`${prefix}/user-view?${query.toString()}`);
  return result;
};

const fetchPermissionView = async (projectId, env, permission, pageId = '') => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId, pageid: pageId });
  const result = memoizedFetchV2(`${prefix}/permission-view/${permission}?${query.toString()}`);
  return result;
};

/**
 * 配置角色权限
 * @param {string} projectId 应用ID
 * @param {string} env 环境ID
 * @param {Object} data 角色 { permissions: {permission: string; pageId: string}[], role: string }
 */
const updateRoleView = async (projectId, env, data) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const { role } = data;
  const result = wujiFetch(`${prefix}/role-view/${encodeURIComponent(role)}?${query.toString()}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return result;
};

/**
 * 新建角色
 * @param {string} projectId 应用ID
 * @param {string} env 环境ID
 * @param {Object} role 角色 { name: string, cname: string, type: '' | 'biz' }
 */
const createRole = async (projectId, env, role) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/role?${query.toString()}`, {
    method: 'POST',
    body: JSON.stringify(role),
  });
  return result;
};

/**
 * 新建角色用户
 * @param {string} projectId 应用ID
 * @param {string} env 环境ID
 * @param {Object} data { bizId: string; type: 'staff'; users: string[];
 * roles: string[]; expireRoles: string[]|string|undefined; expireUsers: string[]|string|undefined}
 * @returns Object[]
 */
const createRoleUser = async (projectId, env, data) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/role-user?${query.toString()}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return result;
};

const deleteRoleUser = async (projectId, env, id) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/role-user/${id}?${query.toString()}`, {
    method: 'DELETE',
  });
  return result;
};

/**
 * 新建角色用户
 * @param {string} projectId 应用ID
 * @param {string} env 环境ID
 * @param {Object} data { role: string; permission: string; pageId: string }
 * @returns Object[]
 */
const createRolePermission = async (projectId, env, data) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/role-permission?${query.toString()}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return result;
};

const deleteRolePermission = async (projectId, env, { id, role, ids = null }) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/role-permission/${id}?${query.toString()}`, {
    method: 'DELETE',
    body: JSON.stringify({ role, ids }),
  });
  return result;
};

const updateRole = async (projectId, env, role) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/role/${encodeURIComponent(role.name)}?${query.toString()}`, {
    method: 'PATCH',
    body: JSON.stringify(role),
  });
  return result;
};

const fetchRoleUsers = async (projectId, env, bizId = '', role = '', { id = '', type = '' } = {}) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId, bizid: bizId, role, id, type: id && !type ? 'staff' : type });
  const result = memoizedFetchV2(`${prefix}/role-user?${query.toString()}`);
  return result;
};

const deleteRole = async (projectId, env, name) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId, name });
  const result = wujiFetch(`${prefix}/role/${encodeURIComponent(name)}?${query.toString()}`, {
    method: 'DELETE',
  });
  return result;
};

const deleteRoleByPost = async (projectId, env, name) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/delete/role?${query.toString()}`, {
    method: 'POST',
    body: JSON.stringify({ name }),
  });
  return result;
};

const deletePermission = async (projectId, env, name) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId, name });
  const result = wujiFetch(`${prefix}/permission/${encodeURIComponent(name)}?${query.toString()}`, {
    method: 'DELETE',
  });
  return result;
};

const fetchFuncs = async (projectId, env = 'prod') => {
  const query = new URLSearchParams({ projectid: projectId, env, size: 'total' });
  const result = memoizedFetchV2(`${getManageApiPrefix(env)}/devtool/scf?${query}`);
  return result;
};

const listApiBinding = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = memoizedFetchV2(`${prefix}/api?${query}`);
  return result;
};

const createApiBinding = async (projectId, env, data) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/api?${query}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return result;
};

const unbind = async (projectId, env, id) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/api/${id}?${query}`, { method: 'DELETE' });
  return result;
};

const fetchCustomWorkflow = async (projectId, env = 'dev') => {
  const workflowId = `${projectId}:${APPLY_WORKFLOW_ID}`;
  const flowConfig = await workflowApi.fetchWorkflow(projectId, env, workflowId);
  return flowConfig;
};

const initCustomWorkflow = async (projectId, env = 'dev') => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/custom-workflow?${query}`, { method: 'POST' });
  return result;
};

const apply = async (projectId, env, data) => {
  const prefix = getPermissionApiPrefix(env);
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${prefix}/apply?${query}`, { method: 'POST', body: JSON.stringify(data) });
  return result;
};

const fetchCollections = async (projectId, envId, { q = '', page = 1, size = 10, count = 0 } = {}) => {
  const prefix = getXyManagePathPrefix({ envId });
  const filter = q ? `collectionId%%${q}` : '';
  const pagination = count ? { count: 1 } : { page, size };
  const query = new URLSearchParams({
    projectid: projectId,
    filter, ...pagination, include: 'id,collectionId,cgiInfo,name' });
  const result = memoizedFetchV2(`${prefix}/datasource/collection?${query}`);
  return result;
};

const fetchProtectedPaths = async (projectId, env) => {
  const prefix = getPermissionApiPrefix(env);
  const uri = `${prefix}/protected-paths?projectid=${projectId}`;
  const result = await memoizedFetchV2(uri);
  return result;
};

const assignUsersIntoRole = async (projectId, env, { role, users, bizId = '' }) => {
  const prefix = getPermissionApiPrefix(env);
  const uri = `${prefix}/assign-users-into-role?projectid=${projectId}`;
  const result = wujiFetch(uri, { method: 'POST', body: JSON.stringify({ role, users, bizId }) });
  return result;
};

const assignRolesIntoUser = async (projectId, env, { userId: user, roles, bizId = '' }) => {
  const prefix = getPermissionApiPrefix(env);
  const uri = `${prefix}/assign-roles-into-user?projectid=${projectId}`;
  const result = wujiFetch(uri, { method: 'POST', body: JSON.stringify({ user, roles, bizId }) });
  return result;
};

const proxySyncPerm = async (projectId, env, { fromEnvId }) => {
  const prefix = getPermissionApiPrefix(env);
  const uri = `${prefix}/proxy-sync?projectid=${projectId}`;
  const result = wujiFetch(uri, { method: 'POST', body: JSON.stringify({ fromEnvId }) });
  return result;
};

export default {
  fetchRoles,
  fetchRolesByPermission,
  fetchPermissions,
  fetchBuiltinPermissionList,
  fetchPagePermissionList,
  fetchFlowPermissionList,
  createPermission,
  updatePermission,
  fetchRolePermission,
  fetchRoleView,
  fetchUserView,
  fetchPermissionView,
  updateRoleView,
  checkPermission,
  debugPermission,
  createRole,
  updateRole,
  deleteRole,
  deleteRoleByPost,
  deletePermission,
  fetchFuncs,
  listApiBinding,
  createApiBinding,
  unbind,
  fetchRoleUsers,
  createRoleUser,
  deleteRoleUser,
  fetchCustomWorkflow,
  initCustomWorkflow,
  apply,
  fetchCollections,
  fetchProtectedPaths,
  assignUsersIntoRole,
  assignRolesIntoUser,
  createRolePermission,
  deleteRolePermission,
  proxySyncPerm,
};
