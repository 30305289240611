import wujiFetch from '@utils/wujiFetch';
import { runtimePath, designPath } from '@/config/constant';

const getHostConfigList = async (projectId, isRuntime) => await wujiFetch(`${isRuntime ? runtimePath : designPath}/hostconfig/list?projectid=${projectId}`);

const getHostList = async projectId => await wujiFetch(`${designPath}/hostconfig/hosts?projectid=${projectId}`);

const deleteHostConfig = async (projectId, id, headers = {}) => await wujiFetch(`${designPath}/hostconfig/${id}?projectid=${projectId}`, {
  method: 'DELETE',
  headers,
});

const createHostConfig = async (projectId, data, headers = {}) => await wujiFetch(`${designPath}/hostconfig?projectid=${projectId}`, {
  method: 'POST',
  body: JSON.stringify(data),
  headers,
});

const updateHostConfig = async (projectId, id, data, headers = {}) => await wujiFetch(`${designPath}/hostconfig/${id}?projectid=${projectId}`, {
  method: 'PATCH',
  body: JSON.stringify(data),
  headers,
});

export default {
  getHostConfigList,
  getHostList,
  deleteHostConfig,
  createHostConfig,
  updateHostConfig,
};
